export default {
  availableNowURL: 'available-now',
  vehiclesWeArmorURL: '/vehicles-we-armor',
  type: 'type',
  suvsURL: 'armored-suvs',
  sedansURL: 'armored-sedans',
  pickupTrucksURL: 'armored-pickup-trucks',
  vansURL: 'armored-vans-and-buses',
  lawEnforcementURL: 'armored-law-enforcement',
  citURL: 'armored-cash-in-transit-cit',
  specialtyURL: 'armored-specialty-vehicles',
  preOwnedURL: 'armored-pre-owned',
  armoredRentalURL: 'armored-rental',
  aboutURL: '/about-us',
  newsURL: '/news',
  designEngineeringURL: '/design-and-engineering',
  manufacturingURL: '/manufacturing',
  shippingLogisticsURL: '/shipping-and-logistics',
  mediaURL: '/media',
  ballisticChartURL: '/ballistic-chart',
  becomeADealerURL: '/become-a-dealer',
  ballisticTestingURL: '/ballistic-testing',
  faqsURL: '/faqs',
  locationsWeServeURL: '/locations-we-serve',
  contactURL: '/contact',
  downloadsURL: '/all-downloads',
  privacyPolicyURL: '/privacy-policy',
  vehiclesWeArmorStock: '/available-now/?vehicles_we_armor=',
  blogsURL: '/blog',

  availableNowTitle: 'Available Now',
  vehiclesWeArmor: 'Vehicles We Armor',
  suvsTitleHeader: 'SUVs',
  sedans: 'Sedans',
  pickupTrucks: 'Pickup Trucks',
  vansBuses: 'Vans & Buses',
  lawEnforcement: 'Law Enforcement',
  CITNavigation: 'Cash-In-Transit (CIT)',
  armoredPreOwned: 'Armored Pre-Owned',
  specialtyVehicles: 'Specialty Vehicles',
  specialOfTheMonth: 'special of the month',
  specialOfTheMonth2: 'special-of-the-month',
  aboutUs: 'About Us',
  aboutTitle: 'About Alpine Armoring',
  headerMediaTitle: 'Videos & Trade Shows',
  designEngineering: 'Design & Engineering',
  manufacturing: 'Manufacturing',
  shippingLogistics: 'Shipping & Logistics',
  faq: 'FAQs',
  headerNewsTitle: 'All News',
  footerNewsTitle: 'News on Armored Vehicles',
  ballisticChartTitle: 'Weapons & Ammunition Chart',
  becomeADealerTitle: 'Become a Dealer',
  ballisticTestingTitle: 'Ballistic Testing',
  footerFaqsTitle: 'Frequently Asked Questions',
  locationsWeServeTitle: 'Locations We Serve',
  footerContactTitle: 'Contact Alpine Armoring',
  downloadsTitle: 'All Downloads',
  footerAvailableNowTitle: 'Armored Vehicles for Sale',
  footerPickupTrucksTitle: 'Armored Pickup Trucks',
  footerPreOwnedTitle: 'Pre-owned Armored Vehicles',
  footerLawEnforcementTitle: 'Armored Law Enforcement Vehicles',
  footerSuvsTitle: 'Armored SUVs',
  vansAndBussesTitle: 'Armored Vans & Buses',
  footerSedansTitle: 'Armored Sedans',
  footerCitTitle: 'Armored Cash-In-Transit Vehicles',
  blogsTitle: 'Blogs and Insights',

  formFullName: 'Full Name',
  salesInquiries: 'Sales Inquiries',
  formEmail: 'Email',
  formMobileNumber: 'Mobile Number',
  formAlternatePhoneNumber: 'Alternate Phone Number',
  formCustomerType: 'Customer Type',
  formCustomerTypeUSGovernment: 'US Government Agency',
  formCustomerTypeForeignGovernment: 'Foreign Government Agency',
  formCustomerTypePrivate: 'Private Sector/Corporate',
  formCustomerTypeIndividual: 'Individual',
  formCustomerTypeNGO: 'NGO',
  formCustomerTypeOther: 'Other',
  formInquiry: 'Your Inquiry',
  formInquirySUVs: 'SUVs & Sedans',
  formInquirySWAT: 'SWAT & APC Trucks',
  formInquiryRiot: 'Riot/Water Cannon Crowd Control',
  formInquiryCIT: 'CIT Vans & Trucks',
  rentalVehicles: 'Rental Vehicles',
  formInquiryParts: 'Parts & Accessories',
  formInquiryWarranty: 'Warranty Related',
  formInquiryDealer: 'To Become a Dealer',
  formInquiryEmployment: 'Employment Opportunity',
  formInquiryOther: 'Other',
  formContactVia: 'I Prefer To Be Contacted Via:',
  formContactViaMobile: 'Mobile',
  formContactViaLandline: 'Landline',
  formContactViaEmail: 'Email',
  formContactViaText: 'Text',
  formContactViaWhatsapp: 'Whatsapp',
  formHear: 'How Did You Hear About Us?',
  formHearInstagram: 'Instagram',
  formHearFacebook: 'Facebook',
  formHearTikTok: 'TikTok',
  formHearYouTube: 'YouTube',
  formHearSearch: 'Search Engine (Google, Bing, etc.)',
  formHearRepeat: 'Repeat Customer',
  formHearEmail: 'Email or Newsletter',
  formHearThird: 'Third-Party Review',
  formHearReferral: 'Referral',
  formHearOther: 'Other',
  formCountry: 'Country',
  formState: 'State',
  formMessage: 'Message',
  formSendMessage: 'Send Message',
  formErrorsNameRequired: 'Name is required',
  formErrorsNameValid: 'Please provide a valid name',
  formErrorsEmailRequired: 'Email is required',
  formErrorsEmailValid: 'Invalid email address',
  formErrorsPhoneValid: 'Please enter a valid phone number',
  formErrorsMobileRequired: 'Mobile Number is required',
  formErrorsMobileValid: 'Please enter a valid mobile number',
  formErrorsCountryRequired: 'Country is required',
  formErrorsStateRequired: 'State is required',
  formSent: 'Sent!',
  error: 'Error',
  partsServiceWarranty: 'Parts, Service &<br /> Warranty',
  contactUs: 'Contact Us',
  followUs: 'Follow us on',
  mostPopularServices: 'All Armored Vehicles we Offer',
  quickLinks: 'Quick Links',
  privacyPolicy: 'Privacy Policy',
  allRightsReserved: 'All Rights Reserved',
  homeOfArmoredVehicles: 'The Home of ArmoredVehicles.com',
  inquiringAbout: 'You are inquiring about',
  thisReadyToShip: 'this ready-to-ship',
  rentalOfThis: 'Rental of this',
  the: 'the',
  vehicleID: 'Vehicle ID',
  moreInformationForm: 'for more information, please complete this form:',
  productionDistribution: 'Production & Distribution',
  distribution: 'Distribution',
  clientsDealers: 'Clients & Dealers',
  contact: 'Contact',
  countries: 'countries',
  states: 'states',
  majorCities: 'major cities',
  moreFAQ: 'More FAQs',
  locationsWeServe: 'Locations We Serve',
  armored: 'Armored',
  forPreorder: 'for preorder',
  alpineShield: 'Alpine Shield',
  cookieTitle: 'We value your privacy',
  cookieText: `We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking "Accept All", you consent to our use of cookies.`,
  acceptAll: 'Accept All',
  rejectAll: 'Reject All',
  noOneProtectsBetter: 'no one protects you better',
  seeAllNews: 'See All News',
  searchPlaceholder: 'Search...',
  noResultsFound: 'No results found!',
  shareCopyLink: 'Copy Link',
  shareCopied: 'Copied!',
  shareCopyFailed: 'Copy Failed',
  shareEmailSubject: 'Check out this article:',
  shareEmailBody: 'I thought you might find this interesting:',
  readMore: 'Read more',
  readyToRent: 'Ready to Rent',
  specialOfTheMonthButton: 'Check Out Our Limited Offer Deals!',
  categoriesMainButton: 'Available Now / Ready-To-Ship',
  weArmor: 'we armor',
  allArmoredVehicles: 'All &nbsp;Armored Vehicles',
  industryPartners: 'Industry Partners',
  videoTagNotSupported: 'Your browser does not support the video tag.',
  select: 'Select',
  all: 'All',
  clearAllFilters: 'Clear all filters',
  filters: 'Filters',
  search: 'Search',
  viewDetails: 'View Details',
  armoredTo: 'Armored to',
  level: 'Level',
  engine: 'Engine',
  trans: 'Trans',
  VIN: 'VIN',
  requestAQuote: 'Request a quote',
  viewInStockAvailability: 'View in-stock availability',
  watch: 'Watch',
  video: 'Video',
  offeredAtProtectionLevels: 'Offered At Protection Levels',
  hpLatestNews: 'Armoring industry insights',
  ballisticTests: 'Ballistic Tests',
  mediaAppearances: 'Media Appearances',
  coolVideos: 'Cool Videos',
  selectedVansAndTrucks: 'Selected Vans and Trucks',
  selectedSUVsAndSedans: 'Selected SUVs and Sedans',
  armoredRental: 'Armored Rental',
  exploreDifferentModels: 'Explore different models of',
  satelliteOffices: 'Satellite Offices',
  internationalOffice: 'International Office',
  internationalPartner: 'International Partner',
  headquarters: 'Headquarters',
  regionalOffice: 'Regional Office',
  countriesWeServiced: 'Countries We Serviced',
  yearsInBusiness: 'Years In Business',
  makesAndModelsWeHaveArmored: 'Makes & Models We Have Armored',
  viewCertificates: 'View Certificates',
  otherIndependentLaboratories: 'Other Independent Laboratories',
  OEMBrochures: 'OEM Brochures',
  PDFDocuments: 'PDF Documents',
  stockVideos: 'Stock Videos',
  armoredVehicles: 'Armored Vehicles',
  author: 'Author',
  authorURL: '/author',
  home: 'Home',
  noVehiclesFound: 'No Vehicles Found',
  frequentlyAskedQuestions: 'Frequently Asked Questions',
  noAnswerProvided: 'No answer provided',
  visitOurRentalsWebsite: 'Visit our rentals website',
  loading: 'Loading...',
  year: 'Year',
  miles: 'Miles',
  drivetrain: 'Drivetrain',
  colorExt: 'Color (Exterior)',
  colorInt: 'Color (Interior)',
  trim: 'Trim',
  wheels: 'Wheels',
  height: 'Height',
  length: 'Length',
  width: 'Width',
  wheelbase: 'Wheelbase',
  weightArmored: 'Weight (Armored)',
  power: 'Power',
  enginePower: 'Engine & Power',
  vehicles: 'Vehicles',
  armoringLevel: 'Armoring Level',
  this: 'This',
  isInStock: 'is in stock and available to ship immediately',
  sold: 'Sold',
  armor: 'Armor',
  windowSticker: 'Window Sticker',
  oem: 'OEM',
  armoringSpecs: 'Armoring Specs',
  forSale: 'for Sale',
  projectileEncyclopedia: 'Projectile Encyclopedia',
  linksToBallisticStandards: 'Links to Ballistic Standards',
  protectionLevelsForOccupantsOfLogisticAndLight:
    'Protection Levels for Occupants of Logistic and Light Armored Vehicles',
  projectileAcronyms: 'Projectile Acronyms',
  otherAcronyms: 'Other Acronyms',
  ammunitionChart: 'Ammunition Chart',
  ballisticCertifications: 'Ballistic Certifications',
  materialTesting: 'Material Testing',
  liveFireTesting: 'Live Fire Testing',
  seeBallisticPostcard: 'See Ballistic Postcard',
  blog: 'Blog',
  lastUpdated: 'Last Updated',
  readTime: 'Read Time',
  advancedTechnology: 'Advanced Technology',
  componentsBallisticMaterial: 'Components & Ballistic Material',
  production: 'Production',
  seeAllVideos: 'See All Videos',
  seeAllTradeShows: 'See All Trade Shows',
  videos: 'Videos',
  tradeShows: 'Trade Shows',
  videosURL: '/videos',
  tradeShowsURL: '/trade-shows',
  news: 'News',
  rentalVehiclesURL: '/rental-vehicles',
  rental: 'Rental',
  armoredSmall: 'armored',
  groundShipping: 'Ground Shipping',
  oceanShipping: 'Ocean Shipping',
  airCargoShipping: 'Air Cargo Shipping',
  exportLicenseGuidelines: 'Export License Guidelines',
  statementConsignee: 'Statement by ultimate consignee & purchaser',
  download: 'Download',
  form: 'Form',
  instructions: 'Instructions',
  toComplete: 'to complete',
  vwaMakesMetaDescription1: 'Make your armored',
  vwaMakesMetaDescription2:
    "secure and stylish with Alpine Armoring's top-tier solutions, from SUVs to luxury cars, customized for ultimate protection",
  pageNotFound: 'Page Not Found',
  pageDoesNotExist: 'We are sorry, but this page does not exist!',
  goToHomepage: 'Go to homepage',
  soldVehiclesURL: '/sold-vehicles',
  chatWithUs: 'Chat with Us',
  conversionMayVary:
    'The actual custom-armoring conversion may vary per each vehicle’s structural configuration and client’s final specs',
  armoringFeaturesFor: 'Armoring Features for',
  overview: 'Overview',
  gallery: 'Gallery',
  dimensions: 'Dimensions',
  armoringFeatures: 'Armoring Features',
  conversionAccessories: 'Conversion Accessories',
  communicationsElectronics: 'Communications & Electronics',
  otherOptions: 'Other Options',
  overviewOf: 'Overview of',
  dimensionsFor: 'Dimensions for',
  conversionAccessoriesFor: 'Conversion Accessories for',
  communicationsElectronicsFor: 'Communications & Electronics for',
  otherOptionalEquipmentFor: 'Other Optional Equipment for',
  make: 'make',
  viewSomeCoolVideos: 'View some cool videos',
  isAvailableForRental: 'is now available for <span>RENTAL</span>',

  soldFlag: 'Sold',
  comingSoonFlag: 'Coming Soon',
  preOwnedFlag: 'Pre-Owned',
  exportOnlyFlag: 'Export Only',
};
