export default {
  availableNowURL: 'disponible-ahora',
  vehiclesWeArmorURL: '/vehiculos-que-blindamos',
  type: 'tipo',
  suvsURL: 'suvs-blindados',
  sedansURL: 'sedanes-blindados',
  pickupTrucksURL: 'camionetas-blindadas',
  vansURL: 'furgonetas-y-autobuses-blindados',
  lawEnforcementURL: 'fuerzas-del-orden-blindadas',
  citURL: 'transporte-blindado-valores-cit',
  specialtyURL: 'vehiculos-blindados-especiales',
  preOwnedURL: 'blindados-pre-usados',
  armoredRentalURL: 'alquiler-blindados',
  aboutURL: '/hacerca-de-nosotros',
  newsURL: '/noticias',
  designEngineeringURL: '/diseno-e-ingenieria',
  manufacturingURL: '/fabricacion',
  shippingLogisticsURL: '/envio-y-logistica',
  mediaURL: '/medios',
  ballisticChartURL: '/tabla-balistica',
  becomeADealerURL: '/conviertase-en-distribuidor',
  ballisticTestingURL: '/pruebas-balisticas',
  faqsURL: '/preguntas-frecuentes',
  locationsWeServeURL: '/ubicaciones-que-servimos',
  contactURL: '/contacto',
  downloadsURL: '/todas-las-descargas',
  privacyPolicyURL: '/politica-de-privacidad',
  vehiclesWeArmorStock: '/disponible-ahora/?vehiculos_que_blindamos=',
  blogsURL: '/blog',

  availableNowTitle: 'Disponible ahora',
  vehiclesWeArmor: 'Vehículos que blindamos',
  suvsTitleHeader: 'SUVs',
  sedans: 'Sedanes',
  pickupTrucks: 'Camionetas',
  vansBuses: 'Furgonetas y autobuses',
  lawEnforcement: 'Fuerzas del Orden',
  CITNavigation: 'Transporte de valores (CIT)',
  armoredPreOwned: 'Blindados pre Usados',
  specialtyVehicles: 'Vehículos especiales',
  specialOfTheMonth: 'especial del mes',
  specialOfTheMonth2: 'especial-del-mes',
  aboutUs: 'Sobre nosotros',
  aboutTitle: 'Sobre Alpine Armoring',
  headerMediaTitle: 'Videos y ferias comerciales',
  designEngineering: 'Diseño e ingeniería',
  manufacturing: 'Fabricación',
  shippingLogistics: 'Envío y logística',
  faq: 'Preguntas frecuentes',
  headerNewsTitle: 'Todas las noticias',
  footerNewsTitle: 'Noticias sobre vehículos blindados',
  ballisticChartTitle: 'Gráfico de Armas y Municiones',
  becomeADealerTitle: 'Conviértase en distribuidor',
  ballisticTestingTitle: 'Pruebas balísticas',
  footerFaqsTitle: 'Preguntas frecuentes',
  locationsWeServeTitle: 'Ubicaciones que servimos',
  footerContactTitle: 'Contacto con Alpine Armoring',
  downloadsTitle: 'Todas las descargas',
  footerAvailableNowTitle: 'Vehículos blindados en venta',
  footerPickupTrucksTitle: 'Pickup Blindados',
  footerPreOwnedTitle: 'Vehículos blindados de segunda mano',
  footerLawEnforcementTitle:
    'Vehiculos Blindados para Fuerzas Militares y Policiales',
  footerSuvsTitle: 'Camionetas Blindadas (SUV)',
  vansAndBussesTitle: 'Furgonetas y Autobuses Blindados',
  footerSedansTitle: 'Sedanes Blindados',
  footerCitTitle: 'Vehículos Blindados para Transporte de Valores',
  blogsTitle: 'Blogs y Perspectivas',

  formFullName: 'Nombre completo',
  salesInquiries: 'Consultas de ventas',
  formEmail: 'Correo electrónico',
  formMobileNumber: 'Número de móvil',
  formAlternatePhoneNumber: 'Número de teléfono alternativo',
  formCustomerType: 'Tipo de cliente',
  formCustomerTypeUSGovernment: 'Agencia del Gobierno de EE. UU.',
  formCustomerTypeForeignGovernment: 'Agencia del Gobierno Extranjero',
  formCustomerTypePrivate: 'Sector privado/Corporativo',
  formCustomerTypeIndividual: 'Individuo',
  formCustomerTypeNGO: 'ONG',
  formCustomerTypeOther: 'Otro',
  formInquiry: 'Tu consulta',
  formInquirySUVs: 'SUVs y Sedanes',
  formInquirySWAT: 'Camiones SWAT y APC',
  formInquiryRiot: 'Control de multitudes con cañón de agua/disturbios',
  formInquiryCIT: 'Furgonetas y camiones CIT',
  rentalVehicles: 'Vehículos de alquiler',
  formInquiryParts: 'Piezas y accesorios',
  formInquiryWarranty: 'Relación de garantía',
  formInquiryDealer: 'Para ser distribuidor',
  formInquiryEmployment: 'Oportunidad de empleo',
  formInquiryOther: 'Otro',
  formContactVia: 'Prefiero ser contactado a través de',
  formContactViaMobile: 'Móvil',
  formContactViaLandline: 'Línea fija',
  formContactViaEmail: 'Correo electrónico',
  formContactViaText: 'Mensaje de texto',
  formContactViaWhatsapp: 'Whatsapp',
  formHear: '¿Cómo te enteraste de nosotros?',
  formHearInstagram: 'Instagram',
  formHearFacebook: 'Facebook',
  formHearTikTok: 'TikTok',
  formHearYouTube: 'YouTube',
  formHearSearch: 'Motor de búsqueda (Google, Bing, etc.)',
  formHearRepeat: 'Cliente recurrente',
  formHearEmail: 'Correo electrónico o boletín',
  formHearThird: 'Reseña de terceros',
  formHearReferral: 'Referencia',
  formHearOther: 'Otro',
  formCountry: 'País',
  formState: 'Estado',
  formMessage: 'Mensaje',
  formSendMessage: 'Enviar mensaje',
  formErrorsNameRequired: 'El nombre es obligatorio',
  formErrorsNameValid: 'Por favor, proporcione un nombre válido',
  formErrorsEmailRequired: 'El correo electrónico es obligatorio',
  formErrorsEmailValid: 'Dirección de correo electrónico no válida',
  formErrorsPhoneValid: 'Por favor, ingrese un número de teléfono válido',
  formErrorsMobileRequired: 'El número de móvil es obligatorio',
  formErrorsMobileValid: 'Por favor, ingrese un número de móvil válido',
  formErrorsCountryRequired: 'El país es obligatorio',
  formErrorsStateRequired: 'El estado es obligatorio',
  formSent: '¡Enviado!',
  error: 'Error',
  partsServiceWarranty: 'Piezas, Servicio y<br />Garantía',
  contactUs: 'Contáctenos',
  followUs: 'Síganos en',
  mostPopularServices: 'Todos los Vehículos Blindados que Ofrecemos',
  quickLinks: 'Enlaces rápidos',
  privacyPolicy: 'Política de privacidad',
  allRightsReserved: 'Todos los derechos reservados',
  homeOfArmoredVehicles: 'El hogar de ArmoredVehicles.com',
  inquiringAbout: 'Está consultando sobre',
  thisReadyToShip: 'este listo para enviar',
  rentalOfThis: 'Alquiler de este',
  the: 'el',
  vehicleID: 'ID del vehículo',
  moreInformationForm: 'para más información, complete este formulario',
  productionDistribution: 'Producción y distribución',
  distribution: 'Distribución',
  clientsDealers: 'Clientes y distribuidores',
  contact: 'Contacto',
  countries: 'países',
  states: 'estados',
  majorCities: 'ciudades principales',
  moreFAQ: 'Más preguntas frecuentes',
  locationsWeServe: 'Ubicaciones que servimos',
  armored: 'Blindado',
  forPreorder: 'para pedido anticipado',
  alpineShield: 'Escudo Alpine',
  cookieTitle: 'Valoramos su privacidad',
  cookieText:
    'Utilizamos cookies para mejorar su experiencia de navegación, mostrar anuncios o contenido personalizado y analizar nuestro tráfico. Al hacer clic en "Aceptar todo", acepta nuestro uso de cookies.',
  acceptAll: 'Aceptar todo',
  rejectAll: 'Rechazar todo',
  noOneProtectsBetter: 'nadie te protege mejor',
  seeAllNews: 'Ver todas las noticias',
  searchPlaceholder: 'Buscar...',
  noResultsFound: '¡No se encontraron resultados!',
  shareCopyLink: 'Copiar enlace',
  shareCopied: '¡Copiado!',
  shareCopyFailed: '¡Falló la copia!',
  shareEmailSubject: 'Mira este artículo',
  shareEmailBody: 'Pensé que podría interesarte',
  readMore: 'Leer más',
  readyToRent: 'Listo para alquilar',
  specialOfTheMonthButton: '¡Consulte nuestras ofertas limitadas!',
  categoriesMainButton: 'Disponible ahora / Listo para enviar',
  weArmor: 'nosotros blindamos',
  allArmoredVehicles: 'Todos los vehículos blindados',
  industryPartners: 'Socios de la industria',
  videoTagNotSupported: 'Su navegador no soporta la etiqueta de video.',
  select: 'Seleccionar',
  all: 'Todo',
  clearAllFilters: 'Borrar todos los filtros',
  filters: 'Filtros',
  search: 'Buscar',
  viewDetails: 'Ver detalles',
  armoredTo: 'Blindado a',
  level: 'Nivel',
  engine: 'Motor',
  trans: 'Transmisión',
  VIN: 'VIN',
  requestAQuote: 'Solicitar presupuesto',
  viewInStockAvailability: 'Ver disponibilidad en stock',
  watch: 'Ver',
  video: 'Video',
  offeredAtProtectionLevels: 'Ofrecido en niveles de protección',
  hpLatestNews: 'Industria de blindaje',
  ballisticTests: 'Pruebas Balísticas',
  mediaAppearances: 'Apariciones en Medios',
  coolVideos: 'Videos Interesantes',
  selectedVansAndTrucks: 'Furgonetas y camiones Seleccionadas',
  selectedSUVsAndSedans: 'SUVs y Sedanes Seleccionados',
  armoredRental: 'Alquiler Blindado',
  exploreDifferentModels: 'Explorar diferentes modelos',
  satelliteOffices: 'Oficinas Satélite',
  internationalOffice: 'Oficina Internacional',
  internationalPartner: 'Socio Internacional',
  headquarters: 'Sede Principal',
  regionalOffice: 'Oficina Regional',
  countriesWeServiced: 'Países que Servimos',
  yearsInBusiness: 'Años en el Negocio',
  makesAndModelsWeHaveArmored: 'Marcas y Modelos Blindados',
  viewCertificates: 'Ver Certificados',
  otherIndependentLaboratories: 'Otros Laboratorios Independientes',
  OEMBrochures: 'Folletos de fabricante de equipos originales',
  PDFDocuments: 'Documentos PDF',
  stockVideos: 'Videos en Existencia',
  armoredVehicles: 'Vehículos Blindados',
  author: 'Autor',
  authorURL: '/autora',
  home: 'Inicio',
  noVehiclesFound: 'No se Encontraron Vehículos',
  frequentlyAskedQuestions: 'Preguntas Frecuentes',
  noAnswerProvided: 'No se Proporcionó Respuesta',
  visitOurRentalsWebsite: 'Visite nuestro sitio web de alquileres',
  loading: 'Cargando...',
  year: 'Año',
  miles: 'Millas',
  drivetrain: 'Tren motriz',
  colorExt: 'Color (Exterior)',
  colorInt: 'Color (Interior)',
  trim: 'Versión',
  wheels: 'Ruedas',
  height: 'Altura',
  length: 'Longitud',
  width: 'Ancho',
  wheelbase: 'Distancia entre ejes',
  weightArmored: 'Peso (Blindado)',
  power: 'Potencia',
  enginePower: 'Motor y Potencia',
  vehicles: 'Vehículos',
  armoringLevel: 'Nivel de blindaje',
  this: 'Esto',
  isInStock: 'Está en inventorio y disponible para envío inmediato',
  sold: 'Vendido',
  armor: 'Blindaje',
  windowSticker: 'Calcomania de ventana',
  oem: 'OEM',
  armoringSpecs: 'Especificaciones de blindaje',
  forSale: 'En venta',
  projectileEncyclopedia: 'Enciclopedia de proyectiles',
  linksToBallisticStandards: 'Enlaces a estándares balísticos',
  protectionLevelsForOccupantsOfLogisticAndLight:
    'Niveles de protección para ocupantes de vehículos logísticos y ligeros blindados',
  projectileAcronyms: 'Acrónimos de proyectiles',
  otherAcronyms: 'Otros acrónimos',
  ammunitionChart: 'Tabla de municiones',
  ballisticCertifications: 'Certificaciones balísticas',
  materialTesting: 'Pruebas de materiales',
  liveFireTesting: 'Pruebas de fuego real',
  seeBallisticPostcard: 'Ver tarjeta postal balística',
  blog: 'Blog',
  lastUpdated: 'Última actualización',
  readTime: 'Tiempo de lectura',
  advancedTechnology: 'Tecnología avanzada',
  componentsBallisticMaterial: 'Componentes y materiales balísticos',
  production: 'Producción',
  seeAllVideos: 'Ver todos los videos',
  seeAllTradeShows: 'Ver todas las ferias comerciales',
  videos: 'Videos',
  tradeShows: 'Ferias comerciales',
  videosURL: '/videos',
  tradeShowsURL: '/ferias-comerciales',
  news: 'Noticias',
  rentalVehiclesURL: '/vehiculos-de-renta',
  rental: 'Renta',
  armoredSmall: 'Blindado',
  groundShipping: 'Envío terrestre',
  oceanShipping: 'Envío marítimo',
  airCargoShipping: 'Envío de carga aérea',
  exportLicenseGuidelines: 'Directrices para la licencia de exportación',
  statementConsignee: 'Declaración del consignatario y comprador final',
  download: 'Descargar',
  form: 'Formulario',
  instructions: 'Instrucciones',
  toComplete: 'para completar',
  vwaMakesMetaDescription1: 'Haz que tu blindado',
  vwaMakesMetaDescription2:
    'sea seguro y elegante con las soluciones de primer nivel de Alpine Armoring, desde SUVs hasta autos de lujo, personalizados para una protección máxima',
  pageNotFound: 'Página no encontrada',
  pageDoesNotExist: 'Lo sentimos, pero esta página no existe',
  goToHomepage: 'Ir a la página principal',
  soldVehiclesURL: '/vehiculos-vendidos',
  chatWithUs: 'Chatea con nosotros',
  conversionMayVary:
    'La conversión de blindaje personalizada real puede variar según la configuración estructural de cada vehículo y las especificaciones finales del cliente',
  armoringFeaturesFor: 'Características de blindaje para',
  overview: 'Resumen',
  gallery: 'Galería',
  dimensions: 'Dimensiones',
  armoringFeatures: 'Características de blindaje',
  conversionAccessories: 'Accesorios para conversión',
  communicationsElectronics: 'Comunicaciones y electrónica',
  otherOptions: 'Otras opciones',
  overviewOf: 'Resumen de',
  dimensionsFor: 'Dimensiones para',
  conversionAccessoriesFor: 'Accesorios para conversión para',
  communicationsElectronicsFor: 'Comunicaciones y electrónica para',
  otherOptionalEquipmentFor: 'Otros equipos opcionales para',
  make: 'marca',
  viewSomeCoolVideos: 'Ver videos interesantes',
  isAvailableForRental: 'disponible para <span>ALQUILER</span>',

  soldFlag: 'Vendido',
  comingSoonFlag: 'Próximamente',
  preOwnedFlag: 'Seminuevos',
  exportOnlyFlag: 'Solo exportación',
};
